import React from "react";
import styles from "./aiAutomatization.module.scss";
import HeaderProducts from "../header/headerProducts";
import aiAutomatization from "../../mockup/aiAutomatization.json";
import TextBox from "../textBox/textBox";
import Accordion from "../accordion/accordion";
import folder from "../../assets/folder1.svg";
import { Helmet } from "react-helmet";

const AiAutomatization = () => (
  <>
    <Helmet>
      <title>{aiAutomatization.metaTitle}</title>
      <meta name="description" content={aiAutomatization.metaDescription} />
      <meta name="keywords" content={aiAutomatization.metaKeywords} />
    </Helmet>
    <div className={styles.headerWrapper}>
      <HeaderProducts mockUpData={aiAutomatization} />
    </div>

    <div className={styles.textWrap}>
      <TextBox text={aiAutomatization.textBox} />
    </div>

    <div className={styles.accordionWrap}>
      <Accordion items={aiAutomatization.items} folder={folder} />
    </div>
  </>
);

export default AiAutomatization;
