import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PageViewTracker } from "./PageViewTracker";
import About from "./components/about/about";
import Blog from "./components/blog/blog";
import Contacts from "./components/contact/contact";
import DigitalMarketing from "./components/digitalMarketing/digitalMarketing";
import Footer from "./components/footer/footer";
import GraphicDesign from "./components/graphicDesign/graphicDesign";
import Header from "./components/header/header";
import ItConsulting from "./components/itConsulting/itConsulting";
import NotFound from "./components/notFound/404";
import PrivacyModal from "./components/privacy/privacyModal";
import PrivacyPolicy from "./components/privacy/privacyPolicy";
import ProductDevelopment from "./components/productDevelopment/productDevelopment";
import React, {useEffect, useState, createContext} from "react";
import ScrollToTop from "./components/scrollTop/scrollTopDefault";
import ScrollToTopButton from "./components/scrollTop/scrollTop";
import Services from "./components/services/services";
import SingleArticle from "./components/blog/singleArticle";
import AiAutomatization from "./components/aiAutomatization/aiAutomatization";
import styles from "./global.module.scss";
import Vacancies from "./components/vacancies/vacancies";
import VacanciesSingle from "./components/vacancies/vacanciesSingle";
import WayFoWork from "./components/wayFoWork/wayFoWork";
import WebDevelopment from "./components/webDevelopment/webDevelopment";
import "typeface-ibm-plex-sans";
import "typeface-ibm-plex-mono";

export const PageViewContext = createContext(null); // Create a context


function App() {
  const [pageViews, setPageViews] = useState(0);
  const [startTime] = useState(Date.now());

  useEffect(() => {
    const checkQualityVisit = () => {
      const timeSpent = (Date.now() - startTime) / 1000;
      if (pageViews >= 4 && timeSpent >= 60) {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'qualityVisit',
            pageViews: pageViews,
            timeSpent: timeSpent,
          });
        }
      }
    };

    const timeoutId = setTimeout(checkQualityVisit, 60000);

    return () => clearTimeout(timeoutId);
  }, [pageViews, startTime]);
 
  return (
    <Router>
      <PageViewContext.Provider value={{ setPageViews }}>
      <PageViewTracker />
        <div className={styles.app}>
          <ScrollToTop />
          <ScrollToTopButton />
          <Header />
          <Routes>
            <Route path="/" element={<Services />} />
            <Route path="/services" element={<Services />} />
            <Route path="/product-development" element={<ProductDevelopment />} />
            <Route
              path="/web-development-services"
              element={<WebDevelopment />}
            />
            <Route path="/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/graphic-design-branding" element={<GraphicDesign />} />
            <Route path="/it-consulting" element={<ItConsulting />} />
            <Route path="/ai-automatization" element={<AiAutomatization />} />
            <Route path="/about" element={<About />} />
            <Route path="/way-of-work" element={<WayFoWork />} />
            <Route path="/contact" element={<Contacts />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<SingleArticle />} />
            <Route path="/vacancies" element={<Vacancies />} />
            <Route path="/vacancies/:slug" element={<VacanciesSingle />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          <PrivacyModal />
        </div>
     </PageViewContext.Provider>
    </Router>
  );
}

export default App;
